import request from "@/utils/request";
import { h } from "vue";
const name = "/aimber";


export function login(data) {
  return request({
    url: name + "/customlogin",
    method: "post",
    data,
  });
}
export function CodeLogin(data) {
  return request({
    url: name + "/customlogin",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    data,
  });
}

//置换token
export function RenewalJwtToken(data) {
  return request({
    url: name + "/user/renewal-token",
    method: "get",
    params: data,
  });
}



//获取验证码
export function SendSmsCode(data) {
  return request({
    url: name + "/public/sms/send-code",
    method: "get",
    params: data,
  });
}


export function UserLogout() {
  return request({
    url:name +  '/logout',
    method: 'post'
  })
}


// h5
export function WechatOpenId(data) {
  return request({
    url: name + "/public/callback",
    method: "get",
    params: data,
  });
}

export function CheckTelExistedPublic(data) {
  return request({
    url: name + "/public/sms/check-telphone",
    method: "get",
    params: data,
  });
}